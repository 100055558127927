<!--
 * @Author: Akarichan
 * @LastEditors: Akarichan
 * @Date: 2021-09-23 21:58:16
 * @LastEditTime: 2021-10-09 11:07:28
-->


<template>
    <div class="about">
        <h1>TODO</h1>
    </div>
</template>
<script>

export default {
    
}
</script>


<style lang="scss">
.about {
    text-align: center;
    padding: 50px;
}
</style>